.Box {
    margin: 20px;
}

.Question {
    margin: 20px;
}

.Submit {
    margin: 20px;
}
